import { graphql } from 'gatsby'
import React, { useState } from 'react'
import CategoryArticle from '../components/category-article/category-article'
import Layout from '../layouts'
import "../styles/categories.scss"
import useSiteMetadata from "../utils/useSiteMetadata"


export const query = graphql`
  {
    allStrapiBrokers {
      edges {
        node {
          Logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          Excerpt
          Title
          slug
          created_at
        }
      }
    }
    strapiBrokerIndex {
      EnableLinkThrough
      Description
      Canonical
      Slug
      Title
    }
  }
`

const BrokerIndex = ({ data }) => {

  const brokerNameLength = data.strapiBrokerIndex.Title.length
  let postsPerPage = 6;
  const [showMore, setShowMore] = useState(postsPerPage);


  // sort brokers by created_at, ASC

  data.allStrapiBrokers.edges.sort(function (firstBroker, secondBroker) {

    return new Date(secondBroker.node.created_at) - new Date(firstBroker.node.created_at);
  });

  const { siteUrl: url } = useSiteMetadata()


  return (
    <Layout
      SEOTitle={data.strapiBrokerIndex.Title}
      SEODescription={''}
      footerDisclaimer={''}
      canonical={data.strapiBrokerIndex.Canonical ? data.strapiBrokerIndex.Canonical : `${url}/${data.strapiBrokerIndex.Slug}`}>
      <section className='blog-articles-container padding-bottom'>
        <div className='uk-width-1-1 category-title-container '>
          <div className='category-title-section'>
            <h1>{data.strapiBrokerIndex.Title}</h1>
            {brokerNameLength >= 70 ? '' : <p>{data.strapiBrokerIndex.Description}</p>}
          </div>
        </div>



        <div className='blog-articles-wrapper lateral-padding'>
          <div data-uk-grid='true' className='uk-grid-match'>

            {
              data?.allStrapiBrokers?.edges.slice(0, showMore).map((item, index) => {
                const { Logo, Excerpt, Title, slug } = item.node;

                return <article className='uk-width-1-1 uk-width-1-3@s uk-padding-small article-container'>
                  <CategoryArticle
                    key={index}
                    title={Title}
                    excerpt={Excerpt}
                    CTAText={Title}
                    CTAUrl={data.strapiBrokerIndex.EnableLinkThrough == true ? `/${data.strapiBrokerIndex.Slug.replace(/[\s+.]/g, "-").toLowerCase()}/${slug}` : ""}
                    image={Logo?.localFile.childImageSharp.gatsbyImageData}
                  />
                </article>
              })
            }
          </div>

          <div className="carousel-action" >
            {data?.allStrapiBrokers?.edges.length > postsPerPage && showMore < data?.allStrapiBrokers?.edges.length ? (
              <div className='read-more-btn_container padding-top '>
                <button onClick={() => setShowMore((prevPosts) => prevPosts + postsPerPage)} className='read-more-btn'>show more</button>
              </div>
            ) : null}
          </div>
        </div>


      </section>

    </Layout>
  )
}

export default BrokerIndex